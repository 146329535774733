import $ from 'jquery';

$(function() {
  if ($('body').is('[data-show-user-guide-tooltip]')) {
    const modalTemplate = `
      <div class="modal fade" data-backdrop="static" id="user-guide-prompt">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <p class="lead">
                Welcome to Virtual Primary Care.
              </p>
              <p class="lead">
                Do you want to read the user guide first?
              </p>
              <p class='small text-muted mb-4'>You can access the user guide at anytime from the top-right dropdown menu.</p>

              <div class="d-flex flex-column">
                <a href="${$('#user-guide-link').attr('href')}" target="_blank" class="btn btn-primary guide-button">
                  Show me the user guide
                </a>

                ${$('#video-guide-link').length > 0 ?
                    `<a href="${$('#video-guide-link').attr('href')}" target="_blank" class="btn btn-primary mt-2 guide-button">
                      Watch the tutorial video
                    </a>`
                  :
                    ''
                }

                <button class="btn btn-sm btn-link mt-2" data-dismiss="modal">
                  Start using the system now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    `

    $(modalTemplate).modal('show').on('shown.bs.modal', function() {
      $('.guide-button').on('click', () => $(this).modal('hide'));
    }).on('hidden.bs.modal', function() {
      $(this).remove()
    });

  }
});