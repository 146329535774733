import $ from 'jquery';

const PromptMsBeforeTimeout = 300 + 5;

const AboutToTimeoutPromptTemplate = `
<div class="modal fade" data-backdrop="static" id="session-about-to-timeout-prompt">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body text-center">
        <p>
          Your session will expire and you will be automatically logged out in
          <br>
          <span class="lead" id="session-about-to-timeout-in">3m 0s</span>
        </p>
        <p class="margin-bottom-0">
          Do you wish to remain logged in?
        </p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary d-block" id="session-timeout-prompt-yes-btn" title="Yes I'm still using the system">
          <i class="far fa-fw fa-check"></i> Yes
        </button>
      </div>
    </div>
  </div>
</div>
`

const SessionTimedOutPromptTemplate = `
<div class="modal fade" data-backdrop="static" id="session-timed-out-prompt">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body text-center">
        <p>Your session has expired.</p>
      </div>
      <div class="modal-footer">
        <a title="Log in again" class="btn btn-primary d-block" href="/">Log in again</a>
      </div>
    </div>
  </div>
</div>
`

class SessionTimeoutChecker {
  constructor(element) {
    this.sessionKey = element.data('session-key');

    this.sessionTimeoutInSeconds = parseInt(element.data('timeout-in'));
    this.pingUrl = element.data('ping-path');

    this.aboutToTimeoutPrompt = $(AboutToTimeoutPromptTemplate);
    this.timedOutPrompt = $(SessionTimedOutPromptTemplate);

    this.lastPinged = this.currentTime();
    this.promptingSessionTimeout = false;

    this.setTimeoutAt();
    this.addListeners();
  }

  start() {
    this.tick();
    this.tickInterval = setInterval(this.tick, 1000);
  }

  stop() {
    clearInterval(this.tickInterval);
  }

  restart() {
    this.stop();
    this.setTimeoutAt();
    this.start();
  }

  tick = () => {
    const timeLeftInSeconds = this.timeoutAt - this.currentTime();
    if (timeLeftInSeconds <= 0) {
      this.showTimeoutPrompt();
    } else if (timeLeftInSeconds <= PromptMsBeforeTimeout) {
      this.showAboutToTimeoutPrompt(timeLeftInSeconds);
    }
  }

  currentTime() {
    return Math.floor(new Date().getTime() / 1000);
  }

  setTimeoutAt() {
    this.timeoutAt = this.currentTime() + this.sessionTimeoutInSeconds;
    localStorage.setItem(this.sessionKey, this.timeoutAt);
  }

  addListeners() {
    $(document.body).on('click', '#session-timeout-prompt-yes-btn', () => {
      this.promptingSessionTimeout = false;
      this.aboutToTimeoutPrompt.modal('hide');
      this.stop();
      this.pingServer();
    });

    $(window).on('storage', e => {
      const event = e.originalEvent;
      if (event.key === this.sessionKey) {
        this.hideAllPrompts();
        this.stop();
        this.timeoutAt = event.newValue;
        this.start();
      }
    });
  }

  showAboutToTimeoutPrompt(timeLeftInSeconds) {
    const minutesForDisplay = Math.floor(timeLeftInSeconds / 60);
    const secondsForDisplay = Math.floor(timeLeftInSeconds - (minutesForDisplay * 60));

    $('#session-about-to-timeout-in', this.aboutToTimeoutPrompt).text(`${minutesForDisplay}m ${secondsForDisplay}s`);

    if (!this.promptingSessionTimeout) {
      this.promptingSessionTimeout = true;
      this.aboutToTimeoutPrompt.modal('show');
    }
  }

  showTimeoutPrompt() {
    this.aboutToTimeoutPrompt.modal('hide');
    this.timedOutPrompt.modal('show');
    this.stop();
  }

  hideAllPrompts() {
    this.promptingSessionTimeout = false;
    this.aboutToTimeoutPrompt.modal('hide');
    this.timedOutPrompt.modal('hide');
  }

  pingServer() {
    $.get(this.pingUrl);
    this.lastPinged = this.currentTime();
  }

  pingServerWithInterval = () => {
    if ((!this.promptingSessionTimeout) && ((this.currentTime() - this.lastPinged) > 10)) {
      this.pingServer();
    }
  }
}

$.fn.sessionTimoutChecker = function (action) {
  this.each(function () {
    let timeoutChecker = $(this).data('session-timeout-checker');
    if (timeoutChecker == null) {
      $(this).data('session-timeout-checker', (timeoutChecker = new SessionTimeoutChecker($(this))));
    }

    timeoutChecker[action]();
  });
};

$(function () {
  if ($('body').is('[data-timeout-in]')) {
    $('body').sessionTimoutChecker('start');
    $(document).ajaxComplete(() => $('body').sessionTimoutChecker('restart'));

    const pingServer = () => $('body').sessionTimoutChecker('pingServerWithInterval');

    $(window).scroll(pingServer);
    $(document).on('keydown click', pingServer);
    $(document).on('ajax-modal-show', () => $('#modalWindow').scroll(pingServer));
  }
});